import { FunctionComponent, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";

import {
  AmplitudeFlagKey,
  FormCategory,
  FormType,
  FormQueryParams,
  getDonationOrTicketingEmbedLink,
  getDonationOrTicketingFormLink,
  getEmbedLeaderboardLink,
  getEmbedThermometerLink,
} from "@simplyk/common";
import { useSearchParams } from "next/navigation";
import { useRouter } from "next/router";

import { AmplitudeEvents } from "../constants/amplitude";
import { lexusTextArt } from "../constants/easterEggs";
import { EMBEDDED_BUTTON_LINK_ATTR } from "../constants/EmbedForm";
import { useCurrentUserContext } from "../contexts/CurrentUserContext";
import { EmbedChoice, ModalContent, ShareFormModalContext } from "../contexts/ShareFormModalContext";
import { useCampaignFeatureFlag } from "../features/CampaignHub/useCampaignFeatureFlag";
import { CampaignPaymentTab } from "../features/CampaignHubPayments/types";
import { AmplitudeVariantValue } from "../gql/gql-types";
import { useUpdateOrganizationFlagsMutation } from "../gql/queries/generated/organizationQuery";
import { trpc } from "../helpers/trpc";
import { useAmplitude } from "../hooks/amplitude/useAmplitude";
import { useAmplitudeFeatureFlag } from "../hooks/amplitude/useAmplitudeFeatureFlag";
import { FRONTEND_URL, OrganizationRoutes } from "../routes/routes";

interface ShareFormModalProviderProps {
  formId: string;
  formType: FormType;
  onClose: () => void;
  amplitudePayload: Record<string, unknown> | undefined;
  isOpen: boolean;
  defaultModalContent?: ModalContent;
}

const defaultChoices = [EmbedChoice.ButtonPopup, EmbedChoice.Form];

const getEmbedHtml = ({
  src,
  iframHeight,
  divHeight,
  divPaddingTop,
  allowPaymentRequest,
}: {
  src: string;
  iframHeight: string;
  divHeight?: string;
  divPaddingTop?: string;
  allowPaymentRequest?: boolean;
}) =>
  // eslint-disable-next-line sonarjs/no-nested-template-literals
  `<div style="position:relative;overflow:hidden;${divHeight ? `height:${divHeight};` : ""}width:100%;${
    divPaddingTop ? `padding-top:${divPaddingTop};` : ""
  }"><iframe title='Donation form powered by Zeffy' style='position: absolute; border: 0; top:0;left:0;bottom:0;right:0;width:100%;height:${iframHeight}' src='${src}' ${
    allowPaymentRequest ? "allowpaymentrequest" : ""
  } allowTransparency="true"></iframe></div>`;

export const ShareFormModalProvider: FunctionComponent<PropsWithChildren<ShareFormModalProviderProps>> = ({
  children,
  formId,
  formType,
  onClose,
  amplitudePayload,
  isOpen,
  defaultModalContent = ModalContent.ShareForm,
}) => {
  const isFormCreation = useSearchParams().get("isFormCreation") === "true";
  const { logAmplitudeEvent } = useAmplitude();
  const { organization, isOrganization } = useCurrentUserContext();
  const { isCampaignsHubFeatureActivated } = useCampaignFeatureFlag();

  const router = useRouter();

  const [updateOrganizationFlags] = useUpdateOrganizationFlagsMutation();
  const [lexusEasterEggCounter, setLexusEasterEggCounter] = useState(0);
  const updateLexusEasterEggCounter = useCallback(
    (value: number) => {
      if (value !== lexusEasterEggCounter + 1) {
        setLexusEasterEggCounter(0);
        return;
      }
      setLexusEasterEggCounter(value);
    },
    [lexusEasterEggCounter]
  );
  useEffect(() => {
    if (lexusEasterEggCounter === 5) {
      // eslint-disable-next-line no-console
      console.log(lexusTextArt);
      setLexusEasterEggCounter(0);
    }
  }, [lexusEasterEggCounter]);

  const { data: shareFormDetails, isLoading } = trpc.getShareFormDetails.useQuery({
    formId,
    formType,
  });

  const rateCount = shareFormDetails?.rateCount;
  const formCategory = shareFormDetails ? FormCategory[shareFormDetails.formCategory] : null;

  useEffect(() => {
    if (isOpen && formCategory) {
      logAmplitudeEvent(AmplitudeEvents.ShareFormModalOpen, { ...amplitudePayload, form_type: formCategory });
    }
  }, [amplitudePayload, formCategory, isOpen, logAmplitudeEvent]);

  const {
    isActive,
    loading: DemotivateEmbedsLoading,
    value,
  } = useAmplitudeFeatureFlag({
    flagKey: AmplitudeFlagKey.DemotivateEmbeds,
    organizationId: organization?.id,
    skip: !!organization?.demotivateEmbeds,
  });
  const demotivateEmbeds = Boolean(isActive || organization?.demotivateEmbeds === AmplitudeVariantValue.B);
  useEffect(() => {
    if (isOrganization && !DemotivateEmbedsLoading && !organization?.demotivateEmbeds && value) {
      updateOrganizationFlags({
        variables: { input: { demotivateEmbeds: value.toUpperCase() as AmplitudeVariantValue } },
      });
    }
  }, [
    DemotivateEmbedsLoading,
    isActive,
    isOrganization,
    organization?.demotivateEmbeds,
    updateOrganizationFlags,
    value,
  ]);

  const formPath = useMemo(
    () =>
      shareFormDetails &&
      getDonationOrTicketingFormLink({
        form: {
          id: formId,
          path: shareFormDetails.path,
          formType,
          formCategory: FormCategory[shareFormDetails.formCategory],
          isPrimaryCampaign: shareFormDetails.isPrimaryCampaign,
          isPrimaryTeam: shareFormDetails.isPrimaryTeam,
        },
        isoLocale: null,
      }),
    [formId, formType, shareFormDetails]
  );

  const embedPath = useMemo(
    () =>
      shareFormDetails &&
      getDonationOrTicketingEmbedLink({
        form: {
          id: formId,
          path: shareFormDetails.path,
          formType,
          formCategory: FormCategory[shareFormDetails.formCategory],
          isPrimaryCampaign: shareFormDetails?.isPrimaryCampaign,
          isPrimaryTeam: shareFormDetails?.isPrimaryTeam,
        },
        isoLocale: null,
      }),
    [formId, formType, shareFormDetails]
  );

  const embedThermometerPath = useMemo(
    () => getEmbedThermometerLink(shareFormDetails?.path || formId, null),
    [shareFormDetails?.path, formId]
  );
  const embedLeaderboardPath = useMemo(
    () => getEmbedLeaderboardLink(shareFormDetails?.path || formId, null),
    [shareFormDetails?.path, formId]
  );
  const embedLink = `${FRONTEND_URL}${embedPath}`;
  const embedHeaderHeightInPx = 154;
  const embedFooterHeightInPx = 68;
  const embedRateHeightInPx = 136;
  const iFrameHeightInPx = (rateCount || 0) * embedRateHeightInPx + embedHeaderHeightInPx + embedFooterHeightInPx;
  const roundedIFrameHeightInPx = Math.min(Math.max(500, iFrameHeightInPx), 1200);
  const formIframeHtml = useMemo(
    () =>
      formType === FormType.DonationForm
        ? getEmbedHtml({ src: embedLink, iframHeight: "100%", divHeight: "1200px", allowPaymentRequest: true })
        : getEmbedHtml({
            src: embedLink,
            iframHeight: "100%",
            divHeight: `${roundedIFrameHeightInPx}px`,
            divPaddingTop: `${roundedIFrameHeightInPx}px`,
            allowPaymentRequest: true,
          }),
    [embedLink, formType, roundedIFrameHeightInPx]
  );

  const hasThermometer = shareFormDetails?.hasThermometer || false;
  const isInCampaign = Boolean(shareFormDetails?.campaignId);

  const choicesMemoized = useMemo(() => {
    const choices = demotivateEmbeds ? [EmbedChoice.LinkedButton, ...defaultChoices] : [...defaultChoices];
    if (hasThermometer) {
      choices.push(EmbedChoice.Thermometer);
    }
    if (isInCampaign) {
      choices.push(EmbedChoice.Leaderboard);
    }
    return choices;
  }, [demotivateEmbeds, hasThermometer, isInCampaign]);

  const [tab, setTab] = useState<EmbedChoice>(choicesMemoized[0]);
  const [isChosen, setIsChosen] = useState(false);
  const [content, setContent] = useState(defaultModalContent);

  const handleClose = useCallback(() => {
    onClose();
    setContent(defaultModalContent);
    setTab(EmbedChoice.LinkedButton);
    setIsChosen(false);
  }, [defaultModalContent, onClose]);
  const onShowShareFormContent = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickBackBase);
    setContent(ModalContent.ShareForm);
  }, [logAmplitudeEvent]);
  const onShowQrCodeContent = useCallback(() => {
    setContent(ModalContent.QrCode);
  }, []);
  const onShowEmbedContent = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickEmbed);
    setContent(ModalContent.Embed);
  }, [logAmplitudeEvent]);

  const onInvitationEmailClick = useCallback(async () => {
    handleClose();

    logAmplitudeEvent(AmplitudeEvents.FormSharingSendInvitationClicked, {
      ticketingId: formId,
    });

    if (isCampaignsHubFeatureActivated) {
      await router.push({
        pathname: OrganizationRoutes.CampaignPayments,
        query: { formId, tab: CampaignPaymentTab.Communication, openInvitationEmail: true },
      });
      return;
    }
    await router.push({
      pathname: OrganizationRoutes.PaymentTable,
      query: { formId, communicationTab: "true", openInvitationEmail: true },
    });
  }, [formId, handleClose, isCampaignsHubFeatureActivated, logAmplitudeEvent, router]);

  const onOtherOptionsClick = useCallback(() => {
    setContent(ModalContent.ShareForm);
  }, []);

  const handleTabChange = useCallback(
    (value: EmbedChoice) => {
      logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickTab, { value });
      setTab(value);
    },
    [logAmplitudeEvent]
  );
  const onChoose = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickChoose, { tab });
    setIsChosen(true);
  }, [logAmplitudeEvent, tab]);
  const handleBack = useCallback(() => {
    logAmplitudeEvent(AmplitudeEvents.ShareFormModalClickBackChoose, { tab });
    setIsChosen(false);
  }, [logAmplitudeEvent, tab]);

  const values = {
    formCategory,
    isLoaded: !isLoading,
    isFormCreation,
    embedChoices: choicesMemoized,
    formLink: formPath ? `${FRONTEND_URL}${formPath}` : undefined,
    formIframeHtml,
    buttonZeffyProperty: `${EMBEDDED_BUTTON_LINK_ATTR}="${embedLink}?${FormQueryParams.Modal}=true"`,
    thermometerIframeHtml: getEmbedHtml({
      src: `${FRONTEND_URL}${embedThermometerPath}`,
      iframHeight: "120px",
      divPaddingTop: "120px",
    }),
    leaderboardIframeHtml: getEmbedHtml({
      src: `${FRONTEND_URL}${embedLeaderboardPath}`,
      divPaddingTop: "240px",
      iframHeight: "100%",
    }),
    updateLexusEasterEggCounter,
    onClose,
    demotivateEmbeds,
    isChosen,
    tab,
    content,
    onShowEmbedContent,
    onShowQrCodeContent,
    handleTabChange,
    onInvitationEmailClick,
    onOtherOptionsClick,
    setContent,
    onShowShareFormContent,
    onChoose,
    handleBack,
    handleClose,
  };
  return <ShareFormModalContext.Provider value={values}>{children}</ShareFormModalContext.Provider>;
};
